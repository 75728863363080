/**
 * Check if script is not loaded already
 * @param {string} src //Script URL to load
 * @returns
 */
function isAlreadyLoaded(src) {
  const scripts = Array.from(document.querySelectorAll('script')).map((scr) => scr.src);
  return scripts.includes(src);
}

/**
 * Function to load third party script
 * @param {string} src //Script URL to load
 * @returns
 */
export function loadScript(src) {
  if (isAlreadyLoaded(src)) {
    return true;
  } else {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        console.error(`Error occurred while loading script: ${src}`);
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
}
