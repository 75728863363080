import React from 'react';
import basketball from '../../assets/img/basketball.png';
// import bin from '../../assets/img/bin.png';
// import { useMutateRemoveCartItem } from '../queries/mutations';

export default function SportListItem({ item, checkBox }) {
  // const { mutateAsync: removeCartItem, isLoading: isRemoving } = useMutateRemoveCartItem({
  //   onSuccess: () => {
  //     typeof onRemove === 'function' && onRemove();
  //   },
  //   onError: (err) => {
  //     console.debug('Error - ', err);
  //   },
  // });

  // const handleRemoveEvent = () => {
  //   removeCartItem({ sport_event: item.name });
  // };
  return (
    <div className="flex gap-4 items-center  py-2.5 md:py-5">
      {checkBox ? (
        <input
          type="checkbox"
          className="text-black w-4 h-4 rounded focus:none border border-gray-400"
        />
      ) : null}
      <div className={`${!checkBox ? 'flex w-full items-center justify-between' : ''}`}>
        <div className="flex gap-3 md:gap-4 items-center">
          <img src={item.image ? item.image : basketball} className="w-6 h-6 rounded-full" alt="" />
          <p className="font-bold text-sm md:text-base text-gray-750">{item.name}</p>
        </div>
        {/* {!checkBox ? ( */}
        {/*  isRemoving ? ( */}
        {/*    <p className="text-xs md:text-sm font-semibold text-red-550">Removing event...</p> */}
        {/*  ) : ( */}
        {/*    <div */}
        {/*      className="flex gap-1 items-center md:items-start cursor-pointer" */}
        {/*      onClick={() => handleRemoveEvent()} */}
        {/*    > */}
        {/*      <img src={bin} alt="bin" className="w-4 h-4 md:w-5 md:h-5" /> */}
        {/*      <p className="text-xs md:text-sm font-semibold text-red-550"> */}
        {/*        <span>Remove Event</span> */}
        {/*      </p> */}
        {/*    </div> */}
        {/*  ) */}
        {/* ) : null} */}
      </div>
    </div>
  );
}
