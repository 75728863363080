import { Fragment, useState } from 'react';
import male from '../../assets/img/male.png';
import schedule from '../../assets/img/schedule.png';
// import pen from '../../assets/img/pen.png';
import bin from '../../assets/img/bin.png';
import basketball from '../../assets/img/basketball.png';
import basketball2 from '../../assets/img/basketball2.png';
import { useMutateRemoveCartItem, useMutateSubmitTournament } from '../queries/mutations';
import { Dialog, Transition } from '@headlessui/react';
import { useEventForm, useFilledDataForm } from '../queries/hooks';
import { Form, Formio } from '@formio/react';
import { useParams } from 'react-router-dom';
import './AddEventForm/form.css';
import { roundOfTwoDigits } from 'utils/formattingUtils';

Formio.setBaseUrl(window.location.origin);

export default function ProductCheckoutItemCard({ item, onRemove }) {
  const [isOpen, setIsOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState({});
  const [form, setForm] = useState();
  const params = useParams();
  useEventForm({
    sportEvent: item.sport_event,
    onSuccess: (res) => {
      setForm(res);
      refetchFormData();
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });

  const { refetch: refetchFormData } = useFilledDataForm({
    formId: item.edit,
    onSuccess: (res) => {
      setSubmissionData(res.form_submission.data);
      openModal();
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });

  const { mutateAsync: removeCartItem, isLoading: isRemoving } = useMutateRemoveCartItem({
    onSuccess: () => {
      typeof onRemove === 'function' && onRemove();
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });

  function closeModal() {
    setIsOpen(false);
    setSubmissionData({});
  }

  function openModal() {
    setIsOpen(true);
  }

  const { submitTournament } = useMutateSubmitTournament({
    onSuccess: (_res) => {},
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });

  const handleRemoveEvent = () => {
    removeCartItem({ sport_event: item.sport_event });
  };

  const submitForm = (data) => {
    submitTournament({
      tournament_id: params.tournament_id,
      formTemplateName: form?.form_template_name,
      formSubmission: data,
    });
  };

  return (
    <>
      <div className="pt-4 pb-10 md:pt-8 md:pb-5 flex justify-between md:px-6 w-full relative">
        <div className="flex items-center md:items-start w-full">
          <img
            src={
              item.sport_image
                ? `${item.sport_image}`
                : [basketball2, basketball].at(Math.floor(Math.random() * 2))
            }
            alt=""
            className="w-14 h-14 rounded-full md:rounded-none md:h-20 md:w-20"
          />
          <div className="flex flex-col md:h-full justify-between ml-4 w-full">
            <div>
              <div className="flex items-center justify-between w-full">
                <p className="text-base font-bold">
                  {item.sport}{' '}
                  <span className="text-gray-570 text-xs">
                    {item.sub_category ? `(${item.sub_category})` : null}
                  </span>
                </p>
                <div className="flex">
                  {isRemoving ? (
                    <p className="text-xs md:text-sm font-semibold text-red-550">
                      Removing event...
                    </p>
                  ) : (
                    <div
                      className="flex gap-1 items-center md:items-start cursor-pointer"
                      onClick={() => handleRemoveEvent()}
                    >
                      <img src={bin} alt="bin" className="w-4 h-4 md:w-5 md:h-5" />
                      <p className="text-xs md:text-sm font-semibold text-red-550">
                        <span>Remove Event</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-2 mt-2 md:mt-0">
              <div className="flex p-1 bg-blue-150 px-0.5 md:px-2 py-0.5 md:py-1 rounded">
                <img src={male} alt="male" className="w-2 h-2 md:w-3 md:h-3 mt-0.5" />
                <p className="text-xxs md:text-xs ml-2.5 whitespace-nowrap text-purple-550">
                  {item.gender}
                </p>
              </div>
              <div className="flex p-1 bg-pink-350 px-0.5 md:px-2 py-0.5 md:py-1 rounded">
                <img src={schedule} alt="schedule" className="h-2 w-2 md:w-3 md:h-3 mt-0.5" />
                <p className="text-xxs md:text-xs ml-2.5 whitespace-nowrap text-pink-750">
                  {item.age_group}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex gap-2 absolute md:relative bottom-2 md:bottom-auto right-2 md:right-auto">
        <div className="gap-1 hidden cursor-pointer">
          <img src={pen} alt="pen" className="w-5 h-5" />
          <p className="text-sm font-semibold text-purple-550">Edit Details</p>
        </div> */}
        {/* {isRemoving ? (
          <p className="text-xs md:text-sm font-semibold text-red-550">Removing event...</p>
        ) : (
          <div
            className="flex gap-1 items-center md:items-start cursor-pointer"
            onClick={() => handleRemoveEvent()}
          >
            <img src={bin} alt="bin" className="w-4 h-4 md:w-5 md:h-5" />
            <p className="text-xs md:text-sm font-semibold text-red-550">
              <span>Remove Event</span>
            </p>
          </div>
        )} */}
        {/* </div> */}
        <div className="text-center flex flex-col absolute bottom-2 right-3 md:bottom-5 md:right-6">
          <p className="text-sm md:text-xl font-bold">₹ {roundOfTwoDigits(item.price * 1.18)}</p>
          <p className="text-xxs text-gray-550 md:text-sm">Per Player</p>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 bg-gray-600 bg-opacity-40 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                <div className="flex justify-center flex-col ">
                  <Dialog.Title as="h4" className="mt-2 text-l font-bold text-gray-700">
                    {form?.form_template_name}
                  </Dialog.Title>
                  <Form
                    form={form?.form}
                    submission={{ data: submissionData }}
                    onSubmit={(schema) => {
                      submitForm(schema);
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
