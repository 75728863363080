import React from 'react';
import { roundOfTwoDigits, roundOfToInt } from 'utils/formattingUtils';
export default function CartItems({
  item,
  tax,
  afterDiscount,
  tournamentFee,
  customName,
  roundToInt,
}) {
  if (tax) {
    return (
      <div className="flex justify-between text-xs text-gray-650 my-1">
        <p>{`${item?.tax_name} (${roundOfTwoDigits(item?.rate)} %)`}</p>
        <p>₹ {roundOfTwoDigits(item?.amount)}</p>
      </div>
    );
  } else if (tournamentFee) {
    return (
      <div
        className={`${item === 0 ? 'hidden' : 'flex'} justify-between text-xs text-gray-650 my-1`}
      >
        <p>One Time Tournament Fee</p>
        <p>₹ {roundOfTwoDigits(item)} </p>
      </div>
    );
  } else if (afterDiscount) {
    return (
      <div className="flex justify-between text-xs text-gray-650 my-1">
        <p>Amount</p>
        <p>₹ {roundOfTwoDigits(item)}</p>
      </div>
    );
  } else {
    return (
      <div className="flex justify-between text-xs text-gray-650 my-1">
        <p>
          {customName ||
            item?.sport_event
              .split('-')
              .splice(1, item?.sport_event.length - 1)
              .join('-')}
        </p>
        {!roundToInt ? (
          <p>₹ {roundOfTwoDigits(item.price)}</p>
        ) : (
          <p>₹ {roundOfToInt(item.price)}</p>
        )}
      </div>
    );
  }
}
