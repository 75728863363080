import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { useCartSummary } from '../queries/hooks';
import Loader from '../../components/Loader';
import { uploadCouponData, removeAppliedCoupon } from '../services';
import { useMutation } from 'react-query';
import CartItems from './CartItems';
import { roundOfToInt } from 'utils/formattingUtils';

export default function PaymentSideBar({ cartSummary, userRole }) {
  const [couponData, setCouponData] = useState('');
  // const [credit, setCredit] = useState(false);
  const { data, isLoading, refetch } = cartSummary;
  const [couponStatus, setCouponStatus] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(false);

  console.log(appliedCoupon);

  useEffect(() => {
    setTimeout(() => {
      setCouponStatus(null);
    }, 3000);
  }, [couponStatus]);

  const changeHandler = ({ target }) => {
    setCouponData(target.value);
    // setCredit(true);
  };

  const formHandler = async (e) => {
    e.preventDefault();
    const value = {
      coupon_code: couponData,
    };
    const res = await uploadDataMutation.mutateAsync(value);
    setCouponData('');
    setCouponStatus(res.message);
    refetch();
  };

  const removeCouponHandler = async (code) => {
    const value = {
      coupon_code: code,
    };
    const res = await removeCouponMutation.mutateAsync(value);
    setCouponStatus(res.message);
    refetch();
  };

  const uploadDataMutation = useMutation((data) => uploadCouponData(data), {
    onSuccess: () => {
      console.log('coupon applied');
    },
    onError: async (err) => {
      console.debug('Error - ', err);
    },
  });

  const removeCouponMutation = useMutation((data) => removeAppliedCoupon(data), {
    onSuccess: () => {
      console.log('coupon applied');
    },
    onError: async (err) => {
      console.debug('Error - ', err);
    },
  });

  const removeCoupon = () => {
    removeCouponHandler(null);
  };

  return (
    <div className="md:order-2 bg-white md:py-8 mt-2 flex  flex-col md:flex-row md:mt-0 justify-center px-4 rounded-xl w-full md:w-80">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <p className="text-base md:text-xl font-bold">Total Amount</p>
          {userRole !== 'institution' ? (
            <div>
              <p className="text-sm text-gray-650 pt-4 pb-2">Coupon Code</p>
              {couponStatus ? getCouponStatus(couponStatus) : null}
              <div className="mb-4">
                <form onSubmit={formHandler} className="flex flex-wrap gap-4">
                  <input
                    required
                    type="text"
                    name=""
                    id=""
                    className="rounded-xl flex-grow border-gray-350 border-1 py-2.5 px-4 text-sm placeholder-gray-450"
                    placeholder="Enter Code"
                    value={couponData}
                    onChange={changeHandler}
                  />
                  <button className="border-1 border-gray-750 hover:text-white hover:bg-blue-800 transition-colors duration-100 text-gray-750 rounded-xl py-2.5 px-4 font-bold">
                    Apply
                  </button>
                </form>
              </div>
            </div>
          ) : null}
          <CouponApply coupunCode={data?.applied_coupon} removeCoupon={removeCoupon} />
          {data?.available_credit > 0 ? (
            <UseSFACredit
              SFACredit={data?.available_credit}
              appliedCoupon={appliedCoupon}
              setAppliedCoupon={setAppliedCoupon}
            />
          ) : null}
          <div className="my-4">
            {userRole === 'institution'
              ? data?.sport_interested?.map((item, index) => <CartItems key={index} item={item} />)
              : data?.sport_events?.map((item, index) => <CartItems key={index} item={item} />)}
            <CartItems item={data?.tournament_fees} tournamentFee={true} />
          </div>
          <div className="flex justify-between text-sm text-gray-650 font-bold shadow-paymentShadow py-4">
            <p>Total</p>
            <p>₹ {data?.total}</p>
          </div>
          {userRole === 'athlete' && !!data?.discount?.coupon ? (
            <>
              <p className="text-sm text-gray-650 font-bold shadow-paymentShadow pt-4 pb-2">
                Discount
              </p>
              <div className="flex justify-between text-xs text-gray-650 pb-4">
                <p>Coupon Code : {data?.discount?.coupon}</p>
                <p>₹ {roundOfToInt(data?.discount?.amount).toString()}</p>
              </div>
            </>
          ) : null}
          <div className="shadow-paymentShadow pt-4 pb-2">
            <CartItems item={data?.amount_after_discount} afterDiscount={true} />
            {data?.taxes?.map((item, index) => (
              <CartItems key={index} tax={true} item={item} />
            ))}
            {data?.applied_coupon ? (
              <CartItems
                item={{
                  price:
                    data?.total +
                    data?.taxes?.reduce((el, el2) => el.amount + el2.amount, { amount: 0 }),
                }}
                customName="Total"
                roundToInt
              />
            ) : null}
          </div>
          {data?.available_credit ? (
            <div className="flex justify-between text-xs text-gray-650 shadow-paymentShadow py-4">
              <p>SFA Credits Available :</p>
              <p>₹ {data?.available_credit.toString()}</p>
            </div>
          ) : null}
          {data?.used_credit > 0 ? (
            <div className="flex justify-between text-xs text-gray-650 shadow-paymentShadow py-4">
              <p>SFA Credits used:</p>
              <p>- ₹ {roundOfToInt(data?.used_credit).toString()}</p>
            </div>
          ) : null}

          <div className="flex justify-between text-gray-650 shadow-paymentShadow md:pt-4 md:pb-0 py-4 ">
            <p className="text-sm font-medium">To be Paid</p>
            <p className="text-lg font-bold">₹ {roundOfToInt(data?.to_be_paid).toString()} </p>
          </div>
        </div>
      )}
    </div>
  );
}

const CouponApply = ({ coupunCode, removeCoupon }) => {
  if (coupunCode && coupunCode.length !== 0) {
    return (
      <div className=" flex justify-between items-center mb-4">
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faCheckCircle} />
          <p className="text-sm text-gray-750">
            Coupon <b>{coupunCode}</b> Applied
          </p>
        </div>
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faTimesCircle}
          onClick={() => removeCoupon()}
        />
      </div>
    );
  }
  return null;
};

const UseSFACredit = ({ SFACredit, appliedCoupon, setAppliedCoupon }) => (
  <div className="bg-purple-550 p-3.5 flex items-center gap-3 rounded-lg">
    <input
      type="checkbox"
      name=""
      id=""
      checked="true"
      disabled="true"
      className="text-indigo-500 w-5 h-5 mr-2 bg-purple-550 focus:ring-purple-550 focus:ring-opacity-25 border-2 border-white rounded"
      onClick={() => setAppliedCoupon(!appliedCoupon)}
    />
    <p className="text-xs text-white">₹ {SFACredit} SFA Credit is available in your account</p>
  </div>
);

const getCouponStatus = (status) => {
  switch (status) {
    case 'Success':
      return (
        <div className="text-center text-sm text-green-450 mb-6">
          <p>Coupon applied successfully!</p>
        </div>
      );
    case 'Coupon removed':
      return (
        <div className="text-center text-sm text-green-450 mb-6">
          <p>Coupon removed!</p>
        </div>
      );
    default:
      return (
        <div className="text-center text-sm text-red mb-6">
          <p>Provided coupon is not valid, please try another coupon</p>
        </div>
      );
  }
};
