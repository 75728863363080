import { useState, useEffect } from 'react';
import Http from 'utils/Http';
import { loadScript } from 'utils/LoadScript';

const apiEndPoints = {
  getAPIKey: 'frappe.integrations.doctype.razorpay_settings.razorpay_settings.get_api_key',
  getOrder: 'sfa_online.v1.registrations.cart.get_order',
  orderPaymentSuccess:
    'frappe.integrations.doctype.razorpay_settings.razorpay_settings.order_payment_success',
  orderPaymentFailure:
    'frappe.integrations.doctype.razorpay_settings.razorpay_settings.order_payment_failure',
};

/**
 * Get razorpay API key
 * @returns
 */
const getKey = async () => {
  const res = await Http.fetch({
    apiEndpoint: apiEndPoints.getAPIKey,
  });
  return res.message;
};

/**
 * Get razorpay order details
 * @param {{ docType, docName }} param0
 * @returns {object}
 */
const getOrder = async ({ docType, docName }) => {
  const res = await Http.fetch({
    method: 'POST',
    apiEndpoint: `${apiEndPoints.getOrder}`,
    options: {
      doctype: docType,
      docname: docName,
    },
  });
  return res.message;
};

export const useRazorpay = ({ prefillModalValues = {}, onOpen, onSuccess, onFail }) => {
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  /**
   * Load razorpay script into DOM
   */
  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }, []);

  /**
   * Make order with provided details
   * @param {{docType: string, docName: string}} param0
   * @returns {object}
   */
  const makeOrder = async ({ docType, docName }) => {
    setIsLoading(true);
    const newOrder = await getOrder({ docType, docName });
    setOrder(newOrder);
    return newOrder;
  };

  /**
   * Function to show razorpay modal
   * It takes customOrder as an optional input param which sets the order details.
   * And if customOrder is not provided then the function considers the order set in the local scope
   * @param {object} customOrder
   */
  const showModal = async (customOrder) => {
    customOrder = customOrder || order;
    if (customOrder.status === 'PAYMENT_DONE') return onSuccess && onSuccess(customOrder.data);
    const config = {
      key: await getKey(),
      handler: (response) => {
        if (response.error) {
          orderFail(response, customOrder.data);
          onFail && onFail(response);
        } else if (response.razorpay_payment_id) {
          orderSuccess(response, customOrder.data);
          onSuccess && onSuccess(response, customOrder.data);
        }
      },
      prefill: prefillModalValues,
    };
    const razorpay = new Razorpay({ ...config, ...customOrder.data }); // eslint-disable-line
    razorpay.once('ready', (response) => {
      onOpen && onOpen(response);
    });
    razorpay.open();
    setIsLoading(false);
  };

  /**
   * Callback triggered on order success
   * @param {object} response
   * @returns {object}
   */
  const orderSuccess = async (response, order) => {
    console.log('order', order);
    const res = await Http.fetch({
      method: 'POST',
      apiEndpoint: `${apiEndPoints.orderPaymentSuccess}?integration_request=${
        order.integration_request
      }&params=${JSON.stringify(response)}`,
    });
    return res.message;
  };

  /**
   * Callback triggered on order fail
   * @param {object} response
   * @returns {object}
   */
  const orderFail = async (response, order) => {
    const res = await Http.fetch({
      method: 'POST',
      apiEndpoint: `${apiEndPoints.orderPaymentFailure}?integration_request=${
        order.integration_request
      }&params=${JSON.stringify(response)}`,
    });
    return res.message;
  };

  return { makeOrder, showModal, isLoading };
};
