import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import MainNav from '../Components/MainNav.js';
import { Footer2Button, Footer3Button } from '../Components/Footers';
import PaymentSideBar from 'registration/Components/PaymentSideBar.js';
import ProductCheckoutItemCard from 'registration/Components/ProductCheckoutItemCard.js';
import { useCartItems, useCartSummary } from '../queries/hooks';
import Loader from '../../components/Loader';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useUserProfile } from '../../profiles/queries/profileHooks';
import SportListItem from '../Components/SportListItem';
import { useRazorpay } from 'hooks/useRazorpay';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMars, faTrash, faVenus } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { deleteSport } from '../slices/registrationStepperSlice';
import { useMutateCoachVerificationRequest } from 'registration/queries/mutations.js';
import { roundOfToInt } from 'utils/formattingUtils.js';
import { transformCartGTM } from 'utils/gtmConfigUtils.js';
import TagManager from 'react-gtm-module';
import { useTournamentDetails } from 'registration/queries/hooks.js';

const mainNavData = {
  tournamentName: 'Tournament 2021',
  startDate: '2021-11-20T00:00:00+05:30',
  endDate: '2021-11-25T00:00:00+05:30',
  tournamentType: 'Inter-School',
  feesInInr: '399',
  banner: 'bg_img',
  tournamentLogo: 'icon_img',
  city: 'Delhi',
};

export default function userPayment() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const tournamentId = params.tournament_id;
  const sportEventsAddedList = useSelector((state) => state.modal);
  const { search } = useLocation();
  const inviteId = new URLSearchParams(search).get('invite_id');
  const tournamentDetails = useTournamentDetails({ tournament_id: tournamentId });
  const submitSportEvents = async (sportEvents) => {
    try {
      const values = {
        tournamentId: tournamentId,
        sportEvents: sportEvents,
      };
      await submitSportEventsAsync(values);
    } catch (e) {
      console.log(e);
    }
  };

  const { mutateAsync: submitSportEventsAsync } = useMutateCoachVerificationRequest({
    nError: () => {},
    onSuccess: () => {},
  });

  const {
    data: { message: userProfile },
  } = useUserProfile();

  const { data, isLoading, refetch } = useCartItems();

  const cartSummary = useCartSummary({ user_id: userProfile.sfa_id });
  const userRole = userProfile.primary_role;

  const {
    makeOrder,
    showModal,
    isLoading: isPaymentLoading,
  } = useRazorpay({
    prefillModalValues: {
      name: userProfile.full_name,
      email: userProfile.email,
      contact: userProfile.phone_number,
    },
    onSuccess: (res, order) => {
      TagManager.dataLayer(
        transformCartGTM(data, cartSummary, res, tournamentId, tournamentDetails, order)
      );

      history.push(`/${params.user}/registration/${params.tournament_id}/payment-success`, {
        cartSummary: cartSummary.data,
        userProfile: userProfile,
      });
    },
    onFail: (res) => {
      console.log(res);
    },
  });

  const makePayment = async () => {
    const order = await makeOrder({
      docType: params.user === 'institution' ? 'Institution Cart' : 'Cart',
      docName: userProfile.email,
    });
    showModal(order);
  };

  const removeHandler = () => {
    refetch();
    cartSummary.refetch();
  };
  if (isPaymentLoading)
    return <Loader text="RE-DIRECTING. DO NOT CLICK BACK BUTTON OR REFRESH THE PAGE."></Loader>;
  return (
    <div className="font-roboto pb-14 md:pb-24">
      <MainNav
        tournamentName={mainNavData.tournamentName}
        startDate={mainNavData.startDate}
        endDate={mainNavData.endDate}
        tournamentType={mainNavData.tournamentType}
        feesInInr={mainNavData.feesInInr}
        banner={mainNavData.banner}
        tournamentLogo={mainNavData.tournamentLogo}
        city={mainNavData.city}
      />
      <div className="flex bg-white md:bg-transparent pb-3 md:px-0 flex-col md:flex-row md:items-start gap-4 md:mt-8 justify-center">
        {isLoading ? (
          <Loader text="RE-DIRECTING. DO NOT CLICK BACK BUTTON OR REFRESH THE PAGE." />
        ) : userRole === 'institution' ? (
          <div
            className={`"hidden md:block order-2 md:order-1 bg-white py-0 md:py-8 px-4 md:px-8 w-full md:w-7/12 rounded-xl h-full overflow-hidden" `}
          >
            <h1 className="md:text-l text-base text-gray-750 font-bold mb-4">Sports Selected</h1>
            {data?.sport_interested?.map((item, index) => {
              return (
                <div key={index} className="">
                  <SportListItem item={item} checkBox={false} onRemove={removeHandler} />
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className={`"order-2 md:order-1 bg-white py-0 md:py-8 px-4 rounded-xl w-full overflow-hidden" ${
              params.user !== 'coach' ? 'md:w-7/12' : 'md:w-4/5'
            }`}
          >
            <div className="border-b-1 pb-1 ">
              <p className="text-base md:text-xl font-bold">Sport Event Selected</p>

              <p className="text-sm text-gray-650 pt-4 pb-2">
                Kindly contact your school's coach to participate in team sports
              </p>
              {/* <p className="text-sm text-gray-650 pt-2 pb-4">
                You can select sport events that you want to participate in here
              </p> */}
            </div>
            {params.user !== 'coach' ? (
              data?.cart_items?.length > 0 ? (
                data?.cart_items?.map((item, index) => {
                  return (
                    <ProductCheckoutItemCard key={index} item={item} onRemove={removeHandler} />
                  );
                })
              ) : (
                <p className="mt-4 font-medium">Go to previous page to Select Sport Events </p>
              )
            ) : (
              <div>
                {sportEventsAddedList.columns.length >= 0 ? (
                  sportEventsAddedList.columns.map((event, index) => {
                    return (
                      <div key={index} className="flex justify-between items-center py-5">
                        <div className="flex gap-4 items-center">
                          <img src={event.image} alt="" className="w-12 h-12" />
                          <div>
                            <p className="text-lg font-bold">
                              {event.sportName}{' '}
                              <span className="text-sm text-gray-450">
                                {event.sportCategory && event.sportCategory}
                              </span>
                            </p>
                            <div className="text-xxs xl:text-xs flex gap-2 my-1.5">
                              {event.gender ? (
                                <button
                                  className={`bg-blue-50 rounded px-2 py-0.5 xl:py-1 flex items-center gap-2 `}
                                >
                                  {event.gender === 'Male' ? (
                                    <FontAwesomeIcon
                                      icon={faMars}
                                      className="text-blue-800 w-2.5 h-2.5 xl:w-3 xl:h-3"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faVenus}
                                      className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
                                    />
                                  )}
                                  <span className={`${'text-blue-800'}`}>{event.gender}</span>
                                </button>
                              ) : null}
                              {event.ageGroup ? (
                                <button className="rounded px-2 py-0.5 xl:py-1 bg-red-50 flex items-center gap-2">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
                                  />
                                  <span className="text-pink-750">{event.ageGroup}</span>
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <button
                          className="text-red-550 text-xs"
                          onClick={() => dispatch(deleteSport(event.index))}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                          &nbsp; Remove Event
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p className="mt-4 font-medium">No Sport Events Added to Cart </p>
                )}
              </div>
            )}
          </div>
        )}
        {params.user !== 'coach' ? (
          <PaymentSideBar cartSummary={cartSummary} userRole={userRole} />
        ) : null}
      </div>
      {params.user === 'coach' && (
        <Footer3Button
          onClick={() => {
            submitSportEvents(sportEventsAddedList.columns);
            history.push(
              `/${params.user}/registration/${params.tournament_id}/${
                params.user === 'coach' ? 'registration-success' : 'payment-success'
              }`
            );
          }}
          nextButtonText="Register Now"
          onCancelPayment={() => {
            history.push(`/${params.user}/registration/${params.tournament_id}/sport-events`);
          }}
          onPrevious={() => {
            history.push(
              `/${params.user}/registration/${params.tournament_id}/${
                params.user !== 'institution' ? 'sport-events' : 'sport-selection'
              }`
            );
          }}
          isLoading={isPaymentLoading}
          size={sportEventsAddedList?.columns.length}
        />
      )}
      {params.user === 'athlete' && (
        <Footer2Button
          inviteId={inviteId}
          tournamentId={tournamentId}
          onClick={makePayment}
          showBack={true}
          previousText={'Add More Events'}
          hideNext={cartSummary.data?.sport_events && cartSummary.data?.sport_events.length === 0}
          nextButtonText={
            cartSummary.data ? `Pay ₹ ${roundOfToInt(cartSummary.data?.to_be_paid)}` : 'Loading...'
          }
          onPrevious={() => {
            history.push(
              `/${params.user}/registration/${params.tournament_id}/${
                params.user !== 'institution'
                  ? `sport-events${inviteId ? `?invite_id=${inviteId}` : ''}`
                  : 'sport-selection'
              }`
            );
          }}
          isLoading={isPaymentLoading}
        />
      )}

      {params.user === 'institution' && (
        <Footer2Button
          onClick={makePayment}
          nextButtonText={
            cartSummary.data ? `Pay ₹ ${roundOfToInt(cartSummary.data?.to_be_paid)}` : 'Loading...'
          }
          onPrevious={() => {
            history.push(
              `/${params.user}/registration/${params.tournament_id}/${
                params.user !== 'institution' ? 'sport-events' : 'sports-selection'
              }`
            );
          }}
          isLoading={isPaymentLoading}
        />
      )}
    </div>
  );
}

// const CouponApply = ({ coupunCode }) => (
//   <div className="flex justify-between items-center my-4">
//     <div className="flex gap-2 items-center">
//       <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
//       <p className="text-sm text-gray-750">
//         Coupon <b>{coupunCode}</b> Applied
//       </p>
//     </div>
//     <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
//   </div>
// );

// const UseSFACredit = ({ SFACredit }) => (
//   <div className="bg-purple-550 p-3.5 flex items-center gap-3 rounded-lg">
//     <input
//       type="checkbox"
//       name=""
//       id=""
//       className="text-indigo-500 w-5 h-5 mr-2 bg-purple-550 focus:ring-purple-550 focus:ring-opacity-25 border-2 border-white rounded"
//     />
//     <p className="text-xs text-white">User ₹ {SFACredit} SFA Credit in your account</p>
//   </div>
// );

// const CartItems = ({ item }) => (
//   <div className="flex justify-between text-xs text-gray-650 my-1">
//     <p>{item.name}</p>
//     <p>₹ {item.price}</p>
//   </div>
// );
